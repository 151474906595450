import React from 'react';
import styled from 'styled-components';
import { Grid, Cell, colors } from '../theme';
import { Container } from 'styled-bootstrap-grid';
import SeperetorTop from './Seperator-top';
import SeperetorBottom from './Seperator-bottom';

const SectionWrapper = styled.div`
  position: relative;
  background: ${colors.blue.footerBackground};
  border-color: ${colors.blue.footerBackground};
  color: ${colors.blue.footerBackground};
  padding-top: 5rem;
  padding-bottom: 10rem;
  text-align: center;
`;
const SectionContainer = styled.div`
  color: ${colors.white};
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const SectionIcon = styled.span`
  padding: 3rem 0;
  svg {
    width: 10rem;
    display: flex;
    align-items: center;
  }
`;
const Header = styled.h3`
  color: ${colors.yellow.hoverFooter};
  margin-bottom: 2rem;
  font-size: 3rem;
`;

const Content = styled.p`
  color: ${colors.white};
  text-align: justify;
  margin-bottom: 1.5rem;
`;

const TransportSection = (props) => {
  return (
    <SectionWrapper>
      <SeperetorTop />
      <Container>
        <Grid rowGap={'1.5rem'} columnGap={'3.5rem'} rows={1} columns={3}>
          <Cell>
            <SectionContainer>
              <SectionIcon>
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="car-alt"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 480 512"
                >
                  <path
                    fill="currentColor"
                    d="M438.66 212.33l-11.24-28.1-19.93-49.83C390.38 91.63 349.57 64 303.5 64h-127c-46.06 0-86.88 27.63-103.99 70.4l-19.93 49.83-11.24 28.1C17.22 221.5 0 244.66 0 272v48c0 16.12 6.16 30.67 16 41.93V416c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32v-32h256v32c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32v-54.07c9.84-11.25 16-25.8 16-41.93v-48c0-27.34-17.22-50.5-41.34-59.67zm-306.73-54.16c7.29-18.22 24.94-30.17 44.57-30.17h127c19.63 0 37.28 11.95 44.57 30.17L368 208H112l19.93-49.83zM80 319.8c-19.2 0-32-12.76-32-31.9S60.8 256 80 256s48 28.71 48 47.85-28.8 15.95-48 15.95zm320 0c-19.2 0-48 3.19-48-15.95S380.8 256 400 256s32 12.76 32 31.9-12.8 31.9-32 31.9z"
                  ></path>
                </svg>
              </SectionIcon>
              <Header>پارکینگ اتومبیل</Header>
              <Content>
                پارکینگ عمومی در نزدیکی محل مطب دکتر محمود سامعی وجود دارد.
              </Content>
              <Content>
                پارکینگ عمومی در نزدیکی محل مرکز پرتودرمانی و آنکولوژی امید
                تهران وجود ندارد.
              </Content>
            </SectionContainer>
          </Cell>
          <Cell>
            <SectionContainer>
              <SectionIcon>
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="wheelchair"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path
                    fill="currentColor"
                    d="M496.101 385.669l14.227 28.663c3.929 7.915.697 17.516-7.218 21.445l-65.465 32.886c-16.049 7.967-35.556 1.194-43.189-15.055L331.679 320H192c-15.925 0-29.426-11.71-31.679-27.475C126.433 55.308 128.38 70.044 128 64c0-36.358 30.318-65.635 67.052-63.929 33.271 1.545 60.048 28.905 60.925 62.201.868 32.933-23.152 60.423-54.608 65.039l4.67 32.69H336c8.837 0 16 7.163 16 16v32c0 8.837-7.163 16-16 16H215.182l4.572 32H352a32 32 0 0 1 28.962 18.392L438.477 396.8l36.178-18.349c7.915-3.929 17.517-.697 21.446 7.218zM311.358 352h-24.506c-7.788 54.204-54.528 96-110.852 96-61.757 0-112-50.243-112-112 0-41.505 22.694-77.809 56.324-97.156-3.712-25.965-6.844-47.86-9.488-66.333C45.956 198.464 0 261.963 0 336c0 97.047 78.953 176 176 176 71.87 0 133.806-43.308 161.11-105.192L311.358 352z"
                  ></path>
                </svg>
              </SectionIcon>
              <Header> دسترسی رمپ</Header>
              <Content>
                دسترسی از طریق رمپ در ساختمان مطب دکتر محمود سامعی موجود نمی
                باشد.
              </Content>
              <Content>
                دسترسی از طریق رمپ در مرکز پرتودرمانی و آنکولوژی امید تهران
                موجود می باشد.
              </Content>
            </SectionContainer>
          </Cell>
          <Cell>
            <SectionContainer>
              <SectionIcon>
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="bus"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path
                    fill="currentColor"
                    d="M488 128h-8V80c0-44.8-99.2-80-224-80S32 35.2 32 80v48h-8c-13.25 0-24 10.74-24 24v80c0 13.25 10.75 24 24 24h8v160c0 17.67 14.33 32 32 32v32c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32v-32h192v32c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32v-32h6.4c16 0 25.6-12.8 25.6-25.6V256h8c13.25 0 24-10.75 24-24v-80c0-13.26-10.75-24-24-24zM112 400c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32zm16-112c-17.67 0-32-14.33-32-32V128c0-17.67 14.33-32 32-32h256c17.67 0 32 14.33 32 32v128c0 17.67-14.33 32-32 32H128zm272 112c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32z"
                  ></path>
                </svg>
              </SectionIcon>
              <Header>ایستگاه اتوبوس</Header>
              <Content>
                ایستگاه اتوبوس در نزدیکی محل مطب دکتر محمود سامعی شماره موجود
                میباشد.
              </Content>
              <Content>
                ایستگاه اتوبوس در نزدیکی مرکز پرتو درمانی و آنکولوژی امید تهران
                شماره موجود میباشد.
              </Content>
            </SectionContainer>
          </Cell>
        </Grid>
      </Container>
      <SeperetorBottom />
    </SectionWrapper>
  );
};

export default TransportSection;
