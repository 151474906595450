import React from 'react';
import styled from 'styled-components';

const SeperatorTopFullWidth = styled.div`
  position: relative;
  background-color: inherit;
  border-color: inherit;
  width: 100%;
  z-index: 1;
  top: 0;
  ::after {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-color: #e5e5e5;
    border-width: 0 0 64px 100vw;
    top: 0;
    border-left-color: transparent;
    border-right-color: transparent;
    left: 0;
    position: absolute;
    z-index: 9;
    transform: rotateX(180deg);
    box-sizing: border-box;
  }
`;

const SeperatorTopFull = () => {
  return <SeperatorTopFullWidth></SeperatorTopFullWidth>;
};

export default SeperatorTopFull;
