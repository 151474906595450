import React from 'react';
import styled from 'styled-components';
import { colors, device } from '../theme';

const Wrapper = styled.div`
  margin-bottom: 1rem;

  @media ${device.laptop} {
    margin-bottom: 5rem;
  }
`;

const Title = styled.div`
  position: relative;
  color: ${(props) => (props.titleColor ? props.titleColor : colors.black)};
  font-size: 1.9rem;
  font-weight: 900;

  @media ${device.laptop} {
    font-size: 3.2rem;
  }
`;

const Subtitle = styled.p`
  color: ${(props) =>
    props.subtitleColor ? props.subtitleColor : colors.gray.headerItemColor};
`;

const ContentHeader = (props) => {
  return (
    <Wrapper>
      <Title titleColor={props.titleColor}>{props.title}</Title>
      <Subtitle subtitleColor={props.subtitleColor}>{props.subtitle}</Subtitle>
    </Wrapper>
  );
};

export default ContentHeader;
