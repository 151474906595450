import React from 'react';
import styled from 'styled-components';
import { colors } from '../theme';
import { Link } from 'gatsby';

const Btn = styled(Link)`
  display: flex;
  justify-content: center;
  background-image: none;
  border: 0px solid transparent;
  font-weight: bold;
  font-size: 1.3rem;
  line-height: 1.5;
  padding: 0.8rem 2.4rem;
  color: ${colors.white};
  background-color: ${(props) =>
    props.backgoundcolor ? props.backgoundcolor : colors.green.greenButton};
  border-color: transparent;
  width: ${(props) => (props.fullwidth ? '100%' : '')};
`;

const BtnExternal = styled.a`
  display: flex;
  justify-content: center;
  background-image: none;
  border: 0px solid transparent;
  font-weight: bold;
  font-size: 1.3rem;
  line-height: 1.5;
  padding: 0.8rem 2.4rem;
  color: ${colors.white};
  background-color: ${(props) =>
    props.backgoundcolor ? props.backgoundcolor : colors.green.greenButton};
  border-color: transparent;
  width: ${(props) => (props.fullwidth ? '100%' : '')};
`;

const FakeButton = styled.div`
  display: flex;
  justify-content: center;
  background-image: none;
  border: 0px solid transparent;
  font-weight: bold;
  font-size: 1.3rem;
  line-height: 1.5;
  padding: 0.8rem 2.4rem;
  color: ${colors.white};
  background-color: ${(props) =>
    props.backgoundcolor ? props.backgoundcolor : colors.green.greenButton};
  border-color: transparent;
  width: ${(props) => (props.fullwidth ? '100%' : '')};
`;

const BtnContainer = styled.div`
  display: flex;
  justify-content: ${(props) => (props.center ? 'center' : 'flex-start')};
  position: relative;
`;

const BtnWithIcon = styled.div`
  display: flex;
  align-items: center;
  background-color: ${(props) =>
    props.backgoundcolor ? props.backgoundcolor : colors.green.greenButtonIcon};
  cursor: pointer;
  width: ${(props) => (props.fullwidth ? '100%' : '')};

  svg {
    width: 1.5rem;
    height: 1.5rem;
    margin: 1rem;
  }
`;

const ButtonLink = (props) => {
  return (
    <BtnContainer center={props.center}>
      <BtnWithIcon
        fullwidth={props.fullWidth}
        backgoundcolor={props.bgColorIcon}
      >
        {props.children}

        {props.isButton && (
          <FakeButton
            onClick={props.onClick}
            backgoundcolor={props.bgColor}
            fullwidth={props.fullWidth}
          >
            {props.title}
          </FakeButton>
        )}
        {props.title &&
          !props.isButton &&
          (props.link?.startsWith('http') ? (
            <BtnExternal
              target="_blank"
              href={props.link}
              backgoundcolor={props.bgColor}
              fullwidth={props.fullWidth}
            >
              {props.title}
            </BtnExternal>
          ) : (
            <Btn
              to={props.link ?? '/'}
              backgoundcolor={props.bgColor}
              fullwidth={props.fullWidth}
            >
              {props.title}
            </Btn>
          ))}
      </BtnWithIcon>
    </BtnContainer>
  );
};

export default ButtonLink;
