import React from 'react';
import styled from 'styled-components';
import GatsbyBackgroundImage from './Gatsby-background-image';
import { device } from '../theme';

const SectionBodyWrapper = styled.section`
  position: relative;
  background-color: #304770;
  border-color: #304770;
  color: white;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 10rem;
  padding-bottom: 5rem;

  ::before {
    display: block;
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    background-color: #efefef;
    background-color: rgba(40, 60, 94, 0.5);
    box-sizing: border-box;
  }

  @media ${device.laptop} {
    padding-top: 128px;
    padding-bottom: 128px;
  }
`;

const SectionBody = (props) => {
  return (
    <SectionBodyWrapper>
      <GatsbyBackgroundImage img={props.img} />
      {props.children}
    </SectionBodyWrapper>
  );
};

export default SectionBody;
