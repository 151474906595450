import React, { useState } from 'react';
import styled from 'styled-components';
import { Grid, Cell, colors } from '../theme';
import ButtonLink from './Buttons';

const Wrapper = styled.div`
  padding: 5rem 0;
`;

const EmptySpace = styled.div`
  height: 64px;
`;

const FormSection = styled.form`
  background-color: ${colors.gray.headerBackground};
  border-radius: 0px;
  color: ${colors.gray.pTagsColor};
  font-size: 14px;
  height: inherit;
  line-height: 1.5;
  padding: 7px 0 7px 22px;
`;

const InputSection = styled.input`
  margin-bottom: 1rem;
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  width: 100%;
  background-color: ${colors.gray.blogGray};
  border: 1px solid ${colors.gray.formBorder};
  color: ${colors.gray.pTagsColor};
  font-size: 14px;
  height: inherit;
  line-height: 1.5;
  padding: 0.8rem 2.2rem;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
`;

const TextAreaSection = styled.textarea`
  margin-bottom: 1rem;
  width: 100%;
  background-color: ${colors.gray.blogGray};
  border: 1px solid ${colors.gray.formBorder};
  color: ${colors.gray.pTagsColor};
  font-size: 14px;
  height: inherit;
  line-height: 1.5;
  padding: 0.8rem 2.2rem;
`;

const FormTitle = styled.p`
  color: ${colors.gray.pTagsColor};
  margin-bottom: 1rem;
`;
const Button = styled.button`
  display: flex;
  justify-content: center;
  background-image: none;
  border: 0px solid transparent;
  font-weight: bold;
  font-size: 1.3rem;
  line-height: 1.5;
  padding: 0.8rem 2.4rem;
  color: ${colors.white};
  background-color: ${(props) =>
    props.bgColor ? props.bgColor : colors.green.greenButton};
  border-color: transparent;
  width: ${(props) => (props.fullwidth ? '100%' : '')};
  cursor: pointer;
`;
const FormsAppointment = () => {
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState('');
  const [formValues, setFormValues] = useState({
    name: '',
    phone: '',
    email: '',
    age: '',
    visitLocation: '',
    message: '',
  });

  const resetForm = (e) => {
    e.preventDefault();
    setSubmitted(false);
  };

  const encode = (data) => {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]),
      )
      .join('&');
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({ 'form-name': 'contact', ...formValues }),
    })
      .then(() => setSubmitted(true))
      .catch((error) => setError(true));
  };

  const handleChange = (e) => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  };

  return (
    <Wrapper>
      <EmptySpace></EmptySpace>
      {!submitted ? (
        <FormSection
          method="POST"
          onSubmit={handleSubmit}
          name="appointment"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
        >
          {error && <FormTitle>{error}</FormTitle>}
          <FormTitle>نام و نام خانوادگی</FormTitle>
          <InputSection
            type="text"
            name="name"
            required
            onChange={handleChange}
          />
          <FormTitle>شماره ی تلفن همراه</FormTitle>
          <InputSection
            type="text"
            name="phone"
            required
            onChange={handleChange}
          />
          <FormTitle>سن</FormTitle>
          <InputSection
            type="text"
            name="age"
            required
            onChange={handleChange}
          />
          <FormTitle>آدرس ایمیل</FormTitle>
          <InputSection
            type="text"
            name="email"
            required
            onChange={handleChange}
          />
          <FormTitle> درخواست ویزیت در تهران یا کرج میباشد؟</FormTitle>
          <InputSection
            type="text"
            name="visitLocation"
            required
            onChange={handleChange}
          />
          <FormTitle>پیام</FormTitle>
          <TextAreaSection
            rows="10"
            name="message"
            required
            onChange={handleChange}
          ></TextAreaSection>
          <input type="hidden" name="form-name" value="appointment" />
          <Button type="submit" bgColor={colors.blue.captionBlue}>
            ارسال
          </Button>
        </FormSection>
      ) : (
        <>
          <FormTitle>با تشکر به زودی با شما تماس خواهیم گرفت</FormTitle>
          <Button onClick={resetForm} bgColor={colors.blue.captionBlue}>
            ارسال مجدد
          </Button>
        </>
      )}
    </Wrapper>
  );
};

export default FormsAppointment;
