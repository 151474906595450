import React from 'react';
import styled from 'styled-components';
import SeperetorBottom from './Seperator-bottom';
import { H1 } from './Title';
import { Container } from 'styled-bootstrap-grid';
import Header from './Header';
import ContentHeader from './Content-header';
import { Grid, Cell, colors } from '../theme';
import TeaserBox from './Teaser-box';
import ButtonLink from './Buttons';
import AppointmentDetails from './Appointment-details';
import FormsAppointment from './Forms-appointment';

const SectionLayout = styled.section`
  position: relative;
  background-color: ${colors.gray.headerBackground};
  border-color: ${colors.gray.headerBackground};
  color: ${colors.gray.headerBackground};
`;

const MainSectionAppointment = () => {
  return (
    <SectionLayout>
      <Container>
        <Grid rowGap={'1.5rem'} rows={1} columns={2}>
          <Cell>
            <FormsAppointment></FormsAppointment>
          </Cell>
          <Cell>
            <AppointmentDetails></AppointmentDetails>
          </Cell>
        </Grid>
      </Container>
    </SectionLayout>
  );
};

export default MainSectionAppointment;
