import React from 'react';
import styled from 'styled-components';
import { H1 } from './Title';
import { Container } from 'styled-bootstrap-grid';
import ButtonLink from './Buttons';
import { Grid, Cell, colors, textAlign, device } from '../theme';

const Wrapper = styled.div``;

const Header = styled.div`
  position: relative;
  color: ${(props) => props.color};
  font-size: 2.5rem;
  font-weight: 900;
  text-align: ${(props) => (props.align ? 'center' : 'right')};
  margin-bottom: 5rem;

  @media ${device.laptop} {
    font-size: 4.5rem;
  }
`;

const Content = styled.p`
  color: ${(props) => props.color};
  margin-top: 1.5rem;
  text-align: ${(props) => (props.align ? 'center' : 'right')};
`;

const BgSectionsHeader = (props) => {
  return (
    <Container>
      <Wrapper>
        <Header color={colors.white} align>
          {props.heading}
          <Content align color={colors.white}>
            {props.subtitle}
          </Content>
        </Header>
      </Wrapper>
      <ButtonLink
        center
        title={'فرم درخواست ویزیت آنلاین'}
        link={'/contact-us'}
      >
        <svg
          aria-hidden="true"
          focusable="false"
          data-prefix="fas"
          data-icon="check"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
        >
          <path
            fill="currentColor"
            d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
          ></path>
        </svg>
      </ButtonLink>
    </Container>
  );
};

export default BgSectionsHeader;
