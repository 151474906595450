import React, { useState } from 'react';
import Layout from '../components/Layout';
import HeadPanel from '../components/HeadPanel';
import SeperatorTopFull from '../components/Seperator-top-full';
import TransportSection from '../components/Section-contact-transport';
import BgImageSection from '../components/Bg-image-section';
import MainSectionAppointment from '../components/Main-section-appointment';
import { useFiles } from '../hooks/files';

const HomePage = () => {
  const { doctorBlueJpeg, map9Slider } = useFiles();

  return (
    <Layout>
      <div>
        <HeadPanel
          img={map9Slider}
          title={'تعیین وقت و پذیرش'}
          subtitle={' نوبت دهی و پذیرش در تهران  و کرج '}
        ></HeadPanel>
        <SeperatorTopFull />
        <MainSectionAppointment />
        <TransportSection />
        <BgImageSection img={doctorBlueJpeg} />
      </div>
    </Layout>
  );
};

export default HomePage;
