import React from 'react';
import styled from 'styled-components';
import { colors, device } from '../theme';
import { Link } from 'gatsby';
import GatsbyImage from 'gatsby-image';

const Wrapper = styled.div`
  background-color: ${colors.blue.captionBlue};
  border-color: ${colors.blue.captionBlue};
  color: ${colors.white};
  position: relative;
  overflow: hidden;
  text-align: center;
  box-sizing: border-box;
  border-radius: 0.5rem;
`;

const Figure = styled.div`
  background-image: url(${(props) => props.bgUrl});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 80%;
  width: 100%;
  position: relative;
  overflow: hidden;
`;

const FigureImage = styled(GatsbyImage)`
  max-height: 200px;
  width: 100%;
  position: relative;
  overflow: hidden;
  @media ${device.laptop} {
    max-height: 350px;
  }
`;

const CaptionBox = styled.div`
  padding: 1.5rem;
  text-align: center;
`;

const CaptionLink = styled(Link)`
  color: ${colors.white};
`;

const TeaserBox = (props) => {
  return (
    <Wrapper>
      {!props.img ? (
        <Figure bgUrl={props.bgUrl}></Figure>
      ) : (
        <FigureImage fluid={props.img} imgStyle={{ objectFit: 'cover' }} />
      )}
      {props.link ? (
        <CaptionLink to={props.link}>
          <CaptionBox>{props.title}</CaptionBox>
        </CaptionLink>
      ) : (
        <CaptionBox>{props.title}</CaptionBox>
      )}
    </Wrapper>
  );
};

export default TeaserBox;
