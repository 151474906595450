import React from 'react';
import styled from 'styled-components';
import { Grid, Cell, colors } from '../theme';
import ButtonLink from './Buttons';
import { Link } from 'gatsby';
import { LINKS } from '../routes';

const BgContainer = styled.div`
  background-color: ${colors.white};
  border-color: ${colors.white};
  padding: 5rem 4rem;
  height: inherit;
`;

const DetailsWrapper = styled.div`
  padding: 8rem 2rem;
`;

const DetailsIcon = styled.img`
  max-width: 100%;
  height: auto;
`;
const AfterH = styled.h2`
  color: black;
  font-weight: 900;
  font-size: 2.5rem;
  width: 100%;
  display: flex;
  white-space: nowrap;
  padding: 1rem 0;
  ::after {
    border-top: 1px solid;
    content: '';
    display: block;
    position: relative;
    top: 0.7em;
    width: 100%;
    right: 1.5%;
  }
`;
const DetailsText = styled.div`
  text-align: justify;
  padding: 3rem 0;
  color: ${colors.gray.pTagsColor};
`;

const DetailsNumber = styled.h3`
  color: ${colors.gray.pTagsColor};
  font-size: 2.5rem;
  font-weight: 900;
`;

const DetailsLinkContainer = styled.div`
  font-weight: bold;
  margin: 2rem 0;
`;
const DetailsLink = styled(Link)`
  color: ${colors.blue.linkHover};

  :hover {
    color: ${colors.gray.pTagsColor};
  }
`;
const AppointmentDetails = () => {
  return (
    <BgContainer>
      <DetailsWrapper>
        <DetailsIcon src="/images/icon-help-desk.png" />
        <DetailsText>
          جهت مراجعه حضوری و تعیین وقت و پذیرش در هر دو مکان کرج و تهران فرم را
          تکمیل نمایید و یا با شماره زیر تماس حاصل نمایید.
        </DetailsText>
        <DetailsNumber>۰۹۳۵۹۳۵۹۹۴۷</DetailsNumber>
        <DetailsText>
          مطب دکتر محمود سامعی : کرج, میدان شهداء, برج نوا, طبقه 4 واحد 6
        </DetailsText>
        <DetailsText>
          کلینیک رادیو تراپی و آنکولوژی امید: تهران، بزرگراه چمران جنوب نرسیده
          به باقرخان، نبش شمالی خیابان کاج، کلینیک امید تهران، پلاک 36
        </DetailsText>
        {/* <DetailsLinkContainer>
          <DetailsLink to={LINKS.contact}>درخواست مشاوره پزشکی</DetailsLink>
        </DetailsLinkContainer>
        <DetailsLinkContainer>
          <DetailsLink to={LINKS.contact}>درخواست ویزیت آنلاین</DetailsLink>
        </DetailsLinkContainer> */}
        <AfterH>ویزیت آنلاین</AfterH>
        <DetailsText>
          شما می توانید به جای مراجعه حضوری، ویزیت تلفنی بصورت تماس صوتی و یا
          تصویری با دکتر محمود سامعی داشته باشید.
        </DetailsText>
        <ButtonLink title={'فرم درخواست ویزیت آنلاین'} link={'/contact-us'}>
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="check"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
          >
            <path
              fill="currentColor"
              d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
            ></path>
          </svg>
        </ButtonLink>
      </DetailsWrapper>
    </BgContainer>
  );
};

export default AppointmentDetails;
