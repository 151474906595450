import React from 'react';
import BgSectionsHeader from './Bg-sections-header';
import SectionBody from './Section-body';

const BgImageSection = (props) => {
  return (
    <SectionBody img={props.img}>
      <BgSectionsHeader
        heading={'درخواست ویزیت آنلاین'}
        subtitle={
          'از طریق ویزیت آنلاین به صورت تصویری و یا صوتی با دکتر محمود سامعی در ارتباط باشید.'
        }
      />
    </SectionBody>
  );
};

export default BgImageSection;
