import React from 'react';
import styled from 'styled-components';

const SeperetorBottomSection = styled.div`
  position: absolute;
  background-color: inherit;
  border-color: inherit;
  width: 100%;
  z-index: 10;
  bottom: 0;

  ::before {
    border-left-color: transparent;
    border-right-color: transparent;
    right: 49.8%;
    bottom: -3.1rem;
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 3.2rem 0 0 90rem;
    border-width: 3.2rem 0 0 50vw;
    border-left-width: 90rem;
    border-left-width: 50vw;
    position: absolute;
    z-index: 9;
    box-sizing: border-box;
  }
  ::after {
    bottom: -3.1rem;
    content: '';
    border-left-color: transparent;
    border-right-color: transparent;
    left: 50%;
    transform: rotateY(180deg);
    position: absolute;
    z-index: 9;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 3.2rem 0 0 90rem;
    border-width: 3.2rem 0 0 50vw;
    border-left-width: 90rem;
    border-left-width: 50vw;
    box-sizing: border-box;
  }
`;

const SeperetorBottom = () => {
  return <SeperetorBottomSection></SeperetorBottomSection>;
};

export default SeperetorBottom;
